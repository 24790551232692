import "../styles/theme.scss";

import "bootstrap";

import mrIsotope from "./mrare/isotope";
import "./mrare/svg-injector";
import "./mrare/flickity";

import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);
